'use strict';

var createNotification = require('eminence/components/notification');

const SELECTORS = {
    newsletterForm: '.js-newsletter-form',
    newsletterInput: '.js-newsletter-input',
    newsletterInputWrapper: '.js-newsletter-input-wrapper',
    notificationMessage: '.js-notification-message, .error-messaging',
    honeypodField: '.js-honeypod-field'
};

/**
 * Init all module related events here
 * @returns {void}
 */
const initEvents = () => {
    $(SELECTORS.newsletterForm).on('submit', handleNewsletterSignUp);
}

/**
 * Get user email value
 * @returns {String} email
 */
const getEmailValue = function () {
    let email = $(SELECTORS.newsletterInput).val();
    return email && email.length ? email.trim() : null;
};

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

/**
 * Handle Newsletter Sign Up
 * @returns {void}
 */
const handleNewsletterSignUp = function (e) {
    e.preventDefault();

    var honeypod = $(SELECTORS.honeypodField);
    if (honeypod.val()) {
        return;
    }

    $(SELECTORS.newsletterInputWrapper).spinner().start();
    if (!validateEmail(getEmailValue())) {
        createNotification($(SELECTORS.notificationMessage), 'Please, provide correct E-mail', 'danger');
        $(SELECTORS.newsletterInputWrapper).spinner().stop();
        return;
    }

    var $form = $(SELECTORS.newsletterForm);
    var url = $form.attr('action');
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $(SELECTORS.newsletterInputWrapper).spinner().stop();
            if (!data.error) {
                createNotification($(SELECTORS.notificationMessage), data.msg, 'success');
            } else {
                createNotification($(SELECTORS.notificationMessage), data.msg, 'danger');
            }
        },
        error: function (error) {
            $(SELECTORS.newsletterInputWrapper).spinner().stop();
            createNotification($(SELECTORS.notificationMessage), error.responseJSON.msg, 'danger');
        }
    });
    return false;
};

module.exports = {
    initEvents: initEvents,
}
