'use strict';

require('slick-carousel/slick/slick');

const SELECTORS = {
    slickSlider: '.js-slick-slider',
    progressBar: '.slick-progress',
    stateButton: '.slick-state-button',
    prevBtn: '.slick-prev',
    nextBtn: '.slick-next',
};

const LOCAL_CLASSES = {
    slickInitialized: 'slick-initialized',
};

let isPaused = false;
let slideDuration = 5000;
let elapsedTime = 0;
let startTime = 0;
let progressTimer = null;

$(document).ready(function () {
    const $slickSliders = $(SELECTORS.slickSlider);
    const progressBar = $(SELECTORS.progressBar);
    const stateButton = $(SELECTORS.stateButton);
    let isAnimating = false;

    $slickSliders.each(function () {
        const $slider = $(this);

        if (!$slider.hasClass(LOCAL_CLASSES.slickInitialized)) {
            $slider.on('init', function () {
                startProgressBar($slider);
            });

            $slider.slick({
                dots: true,
                prevArrow: $(SELECTORS.prevBtn),
                nextArrow: $(SELECTORS.nextBtn),
            });

            $slider.addClass(LOCAL_CLASSES.slickInitialized);
        }

        $slider.on('beforeChange', function () {
            resetProgressBar();
        });

        $slider.on('afterChange', function () {
            if (!isPaused) {
                startProgressBar($slider);
            }
        });
    });

    startProgressBar($slickSliders.eq(0));

    stateButton.on('click', function () {
        isPaused = !isPaused;
        togglePlayPause($(this), $slickSliders);
    });

    /**
     * Starts the progress bar animation and moves to the next slide on completion
     * @param {jQuery} $slider - The slick slider instance
     */
    function startProgressBar($slider) {
        if (isAnimating || isPaused) return;
        isAnimating = true;

        startTime = Date.now() - elapsedTime;

        progressBar.stop().css({ width: `${(elapsedTime / slideDuration) * 100}%` }).animate(
            { width: '100%' },
            slideDuration - elapsedTime,
            'linear',
            function () {
                isAnimating = false;
                elapsedTime = 0;
                if (!isPaused) {
                    $slider.slick('slickNext');
                    startProgressBar($slider);
                }
            }
        );

        progressTimer = setTimeout(() => {
            if (!isPaused) {
                $slider.slick('slickNext');
            }
        }, slideDuration - elapsedTime);
    }

    /**
     * Resets the progress bar to its initial state
     */
    function resetProgressBar() {
        isAnimating = false;
        elapsedTime = 0;
        progressBar.stop().css({ width: '0%' });
        clearTimeout(progressTimer);
    }

    /**
     * Toggles play and pause functionality for the slider and progress bar
     * @param {jQuery} $button - The play/pause button
     * @param {jQuery} $slider - The slick slider instance
     */
    function togglePlayPause($button, $slider) {
        if (isPaused) {
            $button.addClass('slick-play-button').removeClass('slick-pause-button');
            elapsedTime = Date.now() - startTime;
            progressBar.stop();
            clearTimeout(progressTimer);
            $slider.slick('slickPause');
        } else {
            $button.addClass('slick-pause-button').removeClass('slick-play-button');
            $slider.slick('slickPlay');
            startProgressBar($slider);
        }
    }
});
